<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Нийлүүлэгчийн жагсаалт</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                                <router-link to="/supplier-add">
                                    <el-button type="success" class="item">Нэмэх</el-button>
                                </router-link>
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                          <!-- <el-select
                                            style="width: 100%"
                                            v-model="search"
                                            filterable
                                            remote
                                            placeholder="Таван богд"
                                            :remote-method="filterSupplier"
                                            :loading="supplierLoading">
                                            <el-option
                                              v-for="item in suggestedSupplier"
                                              :key="item.supplier_id"
                                              :label="item.supplier_monName"
                                              :value="item.supplier_id">
                                            </el-option>
                                          </el-select> -->
                                      </el-col>
                                      <el-col :span="4" :offset="4" class="text-right">
                                          <el-dropdown @command="handleSort">
                                              <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                                              <el-dropdown-menu slot="dropdown">
                                                  <el-dropdown-item command = 'firstChar'>
                                                      Нэр А - Я
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'lastChar'>
                                                      Нэр Я - А
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'newest'>
                                                      Шинэ эхэнд
                                                  </el-dropdown-item>
                                                  <el-dropdown-item command = 'oldest'>
                                                      Хуучин эхэнд
                                                  </el-dropdown-item>
                                              </el-dropdown-menu>
                                          </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                              <supplier-table :isLoading="isLoading" :pageFrom="pageFrom" :suppliers="suppliers ? suppliers.filter(data => !search || data.supplier_monName.toLowerCase().includes(search.toLowerCase())) : null" :pageSize="pageSize" :search="search"  :suggestedSupplier="suggestedSupplier"></supplier-table>
                           <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page.sync="currentPage"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import supplierTable from './components/supplierTable'
import services from '../../../helpers/services'
import supplierAddForm from './supplierAddForm'
export default {
  components: {
    supplierTable,
    // eslint-disable-next-line vue/no-unused-components
    supplierAddForm: supplierAddForm
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 20,
      page: 1,
      search: '',
      suppliers: [],
      activeTabStatus: 'all',
      pageFrom: 0,
      totalCount: 0,
      isLoading: false,
      visibleSupplierDialog: false,
      supplierLoading: false,
      suggestedSupplier: []
    }
  },

  methods: {
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'supplier', query: { page: this.currentPage, size: this.pageSize } })
      this.getSuppliers((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'supplier', query: { page: item, size: this.pageSize } })
      this.getSuppliers((this.pageSize * (this.currentPage - 1)), this.pageSize)
    },
    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.supplier_monName.localeCompare(b.supplier_monName))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.supplier_monName.localeCompare(a.supplier_monName))
      } else if (method === 'newest') {
        data.sort((a, b) => b.created.localeCompare(a.created_at))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.created.localeCompare(b.created_at))
      }
      return data
    },
    handleSort (data) {
      this.search = ''
      this.suppliers = this.sortMethods(data, this.suppliers)
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    getSuppliers (from, size) {
      const query = '?search_text=' + this.search + '&from=' + from + '&size=' + size
      this.isLoading = true
      services.getSuppliers(query).then(response => {
        this.totalCount = response.total
        this.suppliers = response.datas
        this.isLoading = false
      })
    },

    filterSupplier (val) {
      if (val.length > 1) {
        services.searchSupplier(val).then(response => {
          if (Array.isArray(response.datas) && response.datas.length > 0) {
            this.suggestedSupplier = response.datas
          } else {
            this.suggestedSupplier = []
          }
        })
      }
    }
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getSuppliers(from, size)
  }
}
</script>

<style></style>
